import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NavbarWrapper from '../../common/components/Navbar/index';
import Drawer from '../../common/components/Drawer/index';
import Button from '../../common/components/Button/index';
import Box from '../../common/components/Box';
import HamburgMenu from '../../common/components/HamburgMenu';
import Container from '../../common/components/UI/Container';
import { DrawerContext } from '../../common/contexts/DrawerContext';
import Text from '../../common/components/Text';
//import { NavbarWrapper } from './navbar.style';
//import { MENU_ITEMS } from 'common/data/SaasClassic';
//import ScrollSpyMenu from 'common/components/ScrollSpyMenu';

//import LogoImage from 'common/assets/image/saasClassic/logo-white.png';
//import LogoImageAlt from 'common/assets/image/saasClassic/logo.png';
import { MainStickyWrapper, MainHeading, MainCtaButton } from './stickybutton.style';

const StickyButton = ( { clientConfig } ) => {

    const handleProceedToTest = () => {
        const currentUrlParams = new URLSearchParams( window.location.search );
        const baseUrl = 'https://careertest.qstudyworld.com/qstudyworld/application/TplbkwmqPB3/flow';
        const newUrl = currentUrlParams?.size === 0 ? `${ baseUrl }` : `${ baseUrl }&${ currentUrlParams.toString() }`;
        window.open( newUrl, '_blank' );
    }

    const [countdown, setCountdown] = useState( 10 * 60 * 60 + 49 * 60 + 41 ); // 10 hours, 49 minutes, 41 seconds

    useEffect( () => {
        const timer = setInterval( () => {
            setCountdown( ( prevCountdown ) => prevCountdown - 1 );
        }, 1000 );

        return () => clearInterval( timer );
    }, [] );

    const formatTime = ( timeInSeconds ) => {
        const hours = Math.floor( timeInSeconds / 3600 );
        const minutes = Math.floor( ( timeInSeconds % 3600 ) / 60 );
        const seconds = timeInSeconds % 60;

        return `${ hours }:${ minutes }:${ seconds }`;
    };

    return (
        <MainStickyWrapper style={ { backgroundColor: clientConfig?.secondary_color } }>
            <MainHeading style={ { color: '#000000' } }>
                Free (offer ends in { formatTime( countdown ) })
            </MainHeading>

            <MainCtaButton onClick={ handleProceedToTest } style={ { backgroundColor: clientConfig?.primary_color, color: clientConfig?.secondary_color } }>
                Start Your Assessment!
            </MainCtaButton>
        </MainStickyWrapper>
    );
};

StickyButton.propTypes = {
    clientConfig: PropTypes.object,
};

export default StickyButton;